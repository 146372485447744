$(document).ready(function() {
    var cookieName = "cookies-notification-hide";
    var cookieValue = encodeURIComponent("1");
    var cookieFullValue = cookieValue + "; path=/; max-age=604800";
    if (document.cookie.indexOf(cookieName) === -1) {
        $(".popup-cookie").show();
    }

    $(".popup-cookie__close").click(function(e) {
        document.cookie = encodeURIComponent(cookieName) + "=" + cookieFullValue;
        $(".popup-cookie").hide();
    });
});

$("a[href^=\"#\"]").click(function () { 
	let elementClick = $(this).attr("href").replace(/#/, "");
	if (elementClick && $("a[name="+elementClick+"]").length)
	{
		let destination = $("a[name="+elementClick+"]").offset().top;
		$("html, body").animate( { scrollTop: destination }, 1100 );
		return false;
	}
});
